<template>
    <form action @submit.prevent="changePassword">
        <ValidationObserver ref="validator">
            <div class="d-flex flex-column text-dark-gray validate-form__size" style="gap: 43px;">
                <div class="text-center f-18 mx-auto validate-form-text__size">
                    <p class="text-general mb-2 f-18 f-600">
                        Cambio de contraseña
                    </p>
                </div> 
                <div class="w-80 mx-auto">
                    <ValidationProvider rules="required|max:50" v-slot="{ errors }" vid="password" name="contraseña">
                        <p class="text-center mb-2 f-16">Ingrese su nueva contraseña</p>
                        <input 
                        v-model="model.password" 
                        class="shadow-inner outline-none w-100 border-0 br-8 px-3 input__height"
                        type="password" 
                        >
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="w-80 mx-auto">
                    <ValidationProvider rules="required|max:50|confirmed:password" v-slot="{ errors }" name="verificar contraseña">
                        <p class="text-center mb-2 f-16">Confirmar contraseña</p>
                        <input 
                        v-model="model.verify_password" 
                        class="shadow-inner outline-none w-100 border-0 br-8 px-3 input__height"
                        type="password" 
                        >
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="d-flex justify-content-center custom__mb">
                    <button class="btn btn-general px-5 f-600" type="submit">Aceptar</button>
                </div>
            </div>
        </ValidationObserver>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    middleware: [
        'guest',
        'validate-password'
    ],
    layout:'login',
    computed:{
        ...mapGetters({
            username: 'auth/username'
        })
    },
    data(){
        return {
            model:{
                password: '',
                verify_password: ''
            },
            passwordError: ''
        }
    },
    methods:{
        changePassword(){
            this.$try(async () => {
                const valid = await this.$refs.validator.validate();

                if(!valid) return; 

                this.model.username = this.username;
                this.$store.dispatch('auth/CHANGE_PASSWORD', this.model);
                this.navigateTo({name: 'login'});
            })
        }
    }
}
</script>